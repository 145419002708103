/* eslint-disable @typescript-eslint/no-explicit-any */
export const registrations = [
    {
        name: 'BlogListing',
        import: () => import('@stories/Widgets/BlogListing/BlogListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'PropertySearch',
        import: () => import('@stories/Widgets/PropertySearch/PropertySearch'),
        config: {
            hydrate: 'in-view',
        },
    },
];
